.ag-theme-alpine {
    --ag-checkbox-checked-color: #14d173;
}

.ag-cell[col-id="0"] * {
    font-family: var(--ag-icon-font-family) !important;
}

.ag-input-field-input .ag-checkbox-input {
    font-family: var(--ag-icon-font-family) !important;
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
    font-family: var(--ag-icon-font-family) !important;
}

.ag-header-cell-comp-wrapper {
    justify-content: center;
}

.ag-header-icon {
    width: 36px;
    display: flex;
    height: 36px;
    background: #E5E5E5;

    .icon-filter {
        padding: 5px;
        margin: auto;
    }
}

.resizeable-container {
    box-shadow: 3px 4px 5px 0 #D3D3D3;
    background: #F1F0F0;
    width: 100%;
    resize: both;
    overflow: hidden;

    .ag-layout-normal {
        box-shadow: 3px 4px 5px 0 #D3D3D3;
        border: none !important;

        .ag-header-cell {
            padding: 0 3px;
            border-right: 1px solid #B1B1B1;
        }
    }
}
.MuiPaginationItem-root.Mui-selected{
    color: var(--text-color);
    background-color: #02AA9E !important;
}
.ag-overlay-no-rows-center{
    display: none;
}