
.paper {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    box-shadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)';
    background-color: #E5F8F2;
    form {
        background-color:#FFFFFF ;
        box-shadow: 0px 0px 18px 0px #869B9B40;
        border-radius: 20px;
        width: 530px;
        max-height: 650px;
        padding: 40px 0;
    }
}
.container-page-login {
    max-width: 530px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .block-field {
        width: 80%;
        margin: 20px 0;
        
    }
    .block-reset {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .custom-text-field {
        width: 100%;
    }
}
.register-button {
    color: #3e3e3e;
    width: 100%;
}
.dis-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
}
.text-reset-pass {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 300px;
    text-align: center;
}
.btn-perform {
     background-color: #02AA9E;
     border-radius: 30px;
     height: 50px;
    &:hover {
        background-color: #02AA9E;

    }
}
.container-reset-pass {
    .block-field {
        margin: 27px 0;
    }
}
.block-field a {
    color: #3e3e3e;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}