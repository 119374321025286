.list-account-export {
    .container-export {
        gap: 20px;
        display: flex;
        align-items: center;

        .title-header {
            font-weight: 700;
            font-size: 18px;
        }

        .button-back {
            background: #FFFFFF;
            color: #3E3E3E;
            border: 1px solid #818181;
            width: 123px;
            height: 48px;
            border-radius: 24px;
            display: flex;
            gap: 8px;
        }

        .button-export {
            width: 123px;
            height: 48px;
            border-radius: 24px;
            color: var(--text-color);
            background: var(--button-bg-primary);
        }
        .button-export:disabled {
            color: var(--button-bg-cancel);
            background: var(--button-bg-disabled);
        }
    }
}