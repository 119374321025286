.date-editor{
    margin-left: 10px;
    .MuiFormControlLabel-label{
        font-size: 14px;
    }
    .radio-button{
        font-size: 20px;
        color: var(--radio-button);
    }
    .radio-button-checked{
        font-size: 20px;
        color: var(--radio-button);
    }
}
.date-picker{
    width: 100%;
    margin: auto;
}