.layout-page {
    display: flex;

    .right-menu {
        min-height: 100vh;
        width: 220px;
        background: var(--bg-layout);
        display: flex;
        align-items: center;
        flex-direction: column;
        box-shadow: 0 0 18px 0 #869B9B40;

        .logo {
            width: 209px;
            height: 64px;
            margin-top: 52px;
        }
    }

    .container-children {
        background: #F5F5F5;
        width: calc(100% - 220px);
    }
    .list-menu{
        margin-top: 50px;
        width: 100%;
        .title-menu{
            font-size: 18px;
            color: var(--text-color-black);
        }
        .menu-item{
            min-height: 65px;
        }
        .Mui-selected{
            svg{
                color: #02AA9E;
            }
            .title-menu{
                color: #02AA9E;
            }
            background: var(--config-color-layout);
        }
    }
}