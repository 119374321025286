body, html * {
    font-family: 'Noto Sans JP', sans-serif !important;
}
/*config color css*/
:root{
    --config-color-layout: #E5F8F2;
    --button-bg-primary: #4BC1B8;
    --button-bg-confirm: #4EC3BB;
    --button-bg-disabled: #C0C0C0;
    --button-bg-cancel: #818181;
    --text-color: #FFFFFF;
    --text-color-black: #3E3E3E;
    --bg-modal-confirm:#FFFFFF;
    --bg-modal-success: #4EC3BB;
    --radio-button: #02AA9E;
    --bg-layout: #FFFFFF;
    --color-required: #DC5858;
    --button-warning-cancel: #02AA9EB2;
}
