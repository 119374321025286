.btn-back {
    background-color: #fff;
    display: flex;
    color: #7f7f7f;
    border: 1px solid #7f7f7f;
    width: fit-content;
    justify-content: space-evenly;
    font-size: 16px;
    border-radius: 30px;
    margin-left: 30px;
    height: 48px;
    width: 123px;
}
.btn-change {
    height: 48px;
    width: 123px;
    background-color: #02AA9EB2;
    display: flex;
    color: white;
    justify-content: space-evenly;
    font-size: 16px;
    border-radius: 30px;
    margin-left: 30px;
    &:disabled {
        background-color: grey;
    }
    &:hover {
        background-color: #02AA9EB2;
        color: white;
    }
}