.form-account-settings {
    width: 340px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .select-auto-position-distance {
        padding: 14px;
        justify-content: space-between;
        border-radius: 10px;
        background: var(--text-color);
        display: flex;
        align-items: center;
        gap: 10px;

        .description {
            display: flex;
            flex-direction: column;

            span {
                color: #9E9E9E;
                font-size: 12px;
            }

            .field-title {
                color: #212121;
                font-size: 16px;
            }
        }

        .field-select {
            height: 52px;
        }
    }

    .container-schedule {
        padding: 14px;
        border-radius: 10px;
        background: var(--text-color);
    }

    .account-setting-date {
        .MuiInputBase-root {
            border-radius: 10px;
            background: #F5F5F5;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }
}