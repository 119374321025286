.header-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-hamburger-menu{
        color: var(--text-color-black);
        svg{
            font-size: 30px;
        }
    }
}