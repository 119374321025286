.item-card {
    width: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px 30px 20px;
    height: 150px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px #869B9B40;

}
.title {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 7px;
    color: #3e3e3e;
}
.description {
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    color: #3E3E3E;
}
.icon-card {
    width: 100px;
}
.content-card {
    flex: 1;
    margin-left: 5px;
}