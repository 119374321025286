.title-page {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    line-height: 29px;
    color: var(--text-color-black);
}

.setup-head-item {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 18px;

    button {
        color: var(--text-color);
        width: 123px;
        min-height: 48px;
        border-radius: 24px;
    }

    .button-creat-area {
        background: var(--button-bg-primary);
    }

    .button-creat-area:disabled {
        color: var(--button-bg-cancel);
        background: var(--button-bg-disabled);
    }

    .button-back {
        background: #FFFFFF;
        color: var(--text-color-black);
        border: 1px solid #818181;
        display: flex;
        gap: 8px;
    }
}

.card-item {
    border-radius: 5px;
    box-shadow: 0 0 18px 0 #869B9B40;
    width: 470px;
    height: 120px;

    .card-action-area {
        .item-name {
            .area-name {
                color: var(--text-color-black);
                font-size: 20px;
                font-weight: 700;
            }
        }
    }

    .card-action {
        width: 100%;
        display: flex;
        justify-content: end;

        .content-action {
            margin-bottom: 5px;
            display: flex;
            font-size: 16px;
            color: var(--radio-button);

            img {
                margin-right: 3px;
                color: var(--radio-button);
            }
        }
    }

    .card-action:hover {
        cursor: pointer;
    }
}

.name-field {
    margin: 30px 0;
    display: flex;
    align-items: center;
    gap: 15px;

    .title-field {
        font-size: 18px;
        font-weight: 700;
    }

    .text-field {
        color: var(--text-color-black);
        border-radius: 0;
        width: 192px;
        height: 30px;
    }

    .required-field {
        height: 24px;
        font-size: 10px;
        min-width: 48px;
        border-radius: 10px;
        border: 1px solid var(--color-required);
        color: var(--color-required);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.edit-area {
    margin-top: -15px;
    align-items: center;
    width: 1180px;
    display: flex;
    justify-content: space-between;

    .button-delete {
        width: 103px;
        height: 40px;
        border-radius: 20px;
        color: #08857C;
        font-size: 16px;
        border: 2px solid #08857C
    }

    .button-delete:disabled {
        border-color: #C0C0C0;
        background: #C0C0C0;
        font-weight: 700;
        font-size: 16px;
        color: #818181;
    }
}