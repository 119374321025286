.container-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 716px;
    height: 400px;
    border-radius: 10px;
    background: var(--bg-modal-confirm);
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text-confirm{
        line-break: auto;
        text-align: center;
        font-size: 18px;
        line-height: 15px;
    }
    .container-button{
        margin-top: 10px;
        gap: 40px;
        display: flex;
        button{
            width: 212px;
            min-height: 37px;
            border-radius: 5px;
        }
        .button-cancel{
            background: var(--button-bg-cancel);
        }
        .button-confirm{
            background: var(--button-bg-confirm);
        }
    }
    .msg-warning {
        width: 300px;
        text-align: center;
    }
}