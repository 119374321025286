.custom-checkbox{
    .icon-container{
        width: 24px;
        height: 24px;
        border: 1px solid black;
        background: #ffffff;
        display: inline-block;
    }
    .checked{
        width: 24px;
        height: 24px;
        border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
    }
}