.mobileStyle {
    button {
        width: 200px !important;
        max-width: 125px;
        display: flex;
        font-size: 16px;
        border-radius: 24px;
        height: 48px;
        margin: 15px;
    }

    button:disabled {
        background: grey;
    }
}

.container-modal-date-setting {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 716px;
    height: 380px;
    border-radius: 10px;
    background: #FFFFFF;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .content-modal {
        width: 210px;
        text-align: center;

        .date-picker {
            margin-top: 30px;
            margin-bottom: 30px;

            .MuiFormHelperText-root {
                color: #FF0000;
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: rgba(0, 0, 0, 0.23);
            }
        }
    }

    .container-button {
        justify-content: center;
        display: flex;
        gap: 30px;
        width: 100%;

        button {
            color: #FFFFFF;
            width: 212px;
            height: 37px;
            border-radius: 5px;
        }

        .btn-cancel {
            background: #818181;
        }

        .btn-perform {
            background: #4EC3BB;
        }

        .Mui-disabled {
            background: #C0C0C0 !important;
        }
    }
}

.button-item {
    display: flex;
    align-items: center;

    .button-back {
        background: #FFFFFF;
        color: #3E3E3E;
        border: 1px solid #818181;
        width: 123px;
        height: 48px;
        border-radius: 24px;
        display: flex;
        gap: 8px;
    }
}