.modal-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 716px;
    height: 380px;
    border-radius: 10px;
    background: var(--bg-modal-success);
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-color);

    .button-close-modal {
        position: absolute;
        top: 10px;
        right: 10px;
        color: var(--text-color);
    }

    .container-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 35px;

        img {
            width: 104px;
            height: 81px;
        }

        .text-content {
            font-weight: 700;
            font-size: 18px;
        }
    }
}