.form-change-password{
    width: 416px;
    .note-password{
        margin: 10px 15px 0;
        div{
            display: flex;
            align-items: center;
            gap: 10px;
            svg{
                color: #02AA9E;
                font-size: 16px;
            }
        }
    }
}