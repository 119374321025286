.container-btn-close{
    display: flex;
    justify-content: flex-end;
    .btn-close{
        color: #FFFFFF;
    }
}
.modal-content{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .success-icon{
        font-size: 104px;
    }
}